body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 1140px) {
  .profile {
    display: none;
  }
  .title {
    font-size: medium;
  }
  .subtitle {
    font-size: small;
  }
  .text {
    font-size: x-small;
  }
}

.f-about {
  text-align: left;
}

.f-card {
  margin: 10px;
}

.f-card-img {
  border-radius: 10px;
  width: 330px;
  height: 400px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.f-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.f-card-title {
  font-size: 24px;
  margin: 0px;
}

.f-card-subtitle {
  font-size: 16px;
  margin: 0px;
}

.f-footer {
  background-color: dimgrey;
}

.success-msg {
  color: #57A773;
  padding: 10px 15px;
}

.err-msg {
  color: #ee6352;
  padding: 10px 15px;
}